import React, { Component } from "react";
import loading from "assets/img/loading.svg";

class Loading extends Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          width: "100%",
          alignItems: "center",
          height: "98vh",
          width: "98vw"
        }}
      >
        <img src={loading} alt="" />
      </div>
    );
  }
}

export default Loading;
